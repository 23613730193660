import { useState, useEffect, useRef } from "react";

import './Depositions.css';
import { SvgSetaRight } from "components/SvgFile";

export default function Model_Depositions(props){

    const heightDiv = useRef();
    const [ dataPage, setDataPage ] = useState(props.data);
    const [ showHeight, setShowHeight ] = useState(0);

    function CheckedDiv(){
        const height = [];
        dataPage.contents.map((elem, index)=>{
            if(document.getElementById('height_' + index)){
                height.push(document.getElementById('height_' + index).clientHeight);
            }
        })
        setShowHeight(Math.max.apply(null, height));
    }

    function AltPosition_prev(){
        var stream = document.querySelector('.div_carousel');
        var items = document.querySelectorAll('.div_depositions');

        stream.insertBefore(items[items.length - 1], items[0]);
        items = document.querySelectorAll('.div_depositions');
    }

    function AltPosition_next(){
        var stream = document.querySelector('.div_carousel');
        var items  = document.querySelectorAll('.div_depositions');

        stream.appendChild(items[0]);
        items = document.querySelectorAll('.div_depositions');
    }

    useEffect(()=>{
        const checkedDiv = setInterval(() => {
            if(heightDiv){
                CheckedDiv();
                clearInterval(checkedDiv)
            }    
        }, 100);

        window.addEventListener('resize', function () {
            CheckedDiv();
        });
    }, []);

    return(
        <div className="Model_Depositions" style={ { background: dataPage.bg_color } }>
            <div className="container">
                <div className="title">Depoimentos</div>
                <div className="show_img" ref={ heightDiv } style={ { height: showHeight + "px" } }>

                    <div className="gallery_prev" onClick={ ()=>{ AltPosition_prev('prev') } }>
                        <div className="icons_carousel_prev">
                            <SvgSetaRight color="#ffffff" className="icons_galerry icons_galerry_prev" />
                        </div>
                    </div>
                    <div className="gallery_next" onClick={ ()=>{ AltPosition_next('next') } }>
                        <div className="icons_carousel_next">
                            <SvgSetaRight color="#ffffff" className="icons_galerry" />
                        </div>
                    </div>

                    <div className="div_carousel">
                        {
                            dataPage.contents.map((elem, index)=>{ 
                                return (    
                                    <div className="div_depositions" key={ index }>
                                        <div className="show_data_depositions" id={ "height_" + index }>                                                
                                            <div className="img">
                                                <img alt="profile" src={ elem.file } className="img_profile" />
                                            </div>
                                            <div className="name">{ elem.name }</div>
                                            <div className="biography" dangerouslySetInnerHTML={ { __html: elem.text.replaceAll('&#34;', '"') } } />
                                            <div className="area">
                                                { elem.area }
                                                <div className="date">{ elem.date }</div>
                                            </div>
                                        </div>
                                    </div> 
                                )
                            })
                        } 
                    </div>
                </div>
            </div>
        </div>
    )
}