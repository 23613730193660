import { useState, useEffect } from "react";

import './Recover.css';

import { SvgLogotipo } from "components/SvgFile";

import { useNavigate } from "react-router-dom";

import PopUP_ReturnResponse from "components/PopUp/ReturnResponse";

import { SetModalData, SetModalState } from "interface/PopUp";

import { Reg_ResetPassw } from "services/Login";

export default function Page_Recover(props){
    const [ password, setPassword ] = useState('');
    const [ copyPass, setCopyPass ] = useState('');

    const navigate = useNavigate(); 

    function Register(event){
        props.setLoading(true);
        event.preventDefault();
        Reg_ResetPassw(password, props.code, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        setTimeout(() => {
            setPassword('');
            setCopyPass('');
            props.setLoading(false);
            
            navigate("/login");
            props.CkickPage('page', 'login');

            SetModalData('ReturnResponse', { "page": "ok", "type": "login" });
            SetModalState('ReturnResponse', true);
        }, 1200);
    }

    function CallbackError(){
        setTimeout(() => {
            props.setLoading(false);
            SetModalData('ReturnResponse', { "page": "erro_password", "type": "" });
            SetModalState('ReturnResponse', true);
        }, 1200);        
    }

    function CheckedPassword(){
        if(copyPass !=''){
            if(password == copyPass){
                return(
                    <div className="div_button">
                        <button type="submit" className="button_login">Salvar</button>
                    </div>  
                )
            }else {
                return(
                    <div className="msg_error">
                        As senhas devem ser iguais!
                    </div>
                )
            }
        }else {
            return(
                <div className="msg_error">As senhas acima devem ser iguais!</div>
            )
        }
    }

    useEffect(()=>{
        CheckedPassword();

        document.title = 'Recuperar senha';
    }, [copyPass]);

    return(
        <div className="Recover">
            <div className="Page_Login">
                <div className="logotipo">
                    <SvgLogotipo widthLogo="width_logo" className="svg_logotipo" />
                </div>
                <form onSubmit={ Register } className="div_login">
                    <div className="show_login">
                        <div className="div_input">
                            <div className="">Nova senha</div>
                            <div className="space_input">
                                <input type="password" onChange={ (e)=>{ setPassword(e.target.value) } } minLength={ 6 } placeholder="****" value={ password } required />
                            </div>
                        </div> 
                        <div className="div_input">
                            <div className="">Digite novamente a senha</div>
                            <div className="space_input">
                                <input type="password" onChange={ (e)=>{ setCopyPass(e.target.value) } } minLength={ 6 } placeholder="****" value={ copyPass } required />
                            </div>
                        </div>  
                        {
                            CheckedPassword()
                        }  
                    </div>
                </form>
                <PopUP_ReturnResponse />
            </div>
        </div>
    )
}