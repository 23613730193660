import React, { useState, useEffect } from "react";

import './ShowFile.css';

import { SvgClose } from "components/SvgFile";

import { RegisterModalData, RegisterModalObserver, SetModalState } from "interface/PopUp";

export default function PopUp_ShowFile(){

    const [ modalData, setModaldata ] = useState({});
    const [ showPopUp, setShowPopUp ] = useState(false);

    const [ type, setType ]   = useState('');
    const [ video, setVideo ] = useState('');

    useEffect(()=>{
        RegisterModalData('ShowFile', setModaldata);
        RegisterModalObserver('ShowFile', setShowPopUp);
    }, []);

    useEffect(()=>{
        if(showPopUp == true){
            setType(modalData.type);
            setVideo(modalData.file);
        }
    }, [showPopUp]);

    function ClosePopUp(){
        SetModalState('ShowFile', false);
    }

    function OpenFile(){
        switch (type) {
            case 'Vimeo':
                return (
                    <div className="aspect_video">
                        <iframe className="div_video" src={ "https://player.vimeo.com/video/" + video } frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen="" />
                    </div>
                );
                
            case 'Youtube':
                return (
                    <div className="aspect_video">
                        <iframe className="div_video" src={ "https://www.youtube.com/embed/" + video } frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen="" />
                    </div>
                );
        }
    }

    return (
        (showPopUp ?
            <>
                <div className="PopUp">
                    <div className="all ShowFile">
                        <div className="div_data">
                            <div className="title title_file">
                                <div className="popup_close" onClick={ ()=>{ ClosePopUp(); } }>
                                    <SvgClose color="#F00000" className="icons" />
                                </div>
                            </div>
                        </div>
                        <div className="div_data" style={ { paddingTop: 0 } }>
                            <div className="content">
                                {
                                    type == "" ?
                                    <img alt="loading" src="./assets/loading.gif" className="loading" /> :
                                    OpenFile()
                                }
                            </div>
                        </div>
                    </div>
                    <div className="close_all" onClick={ ()=>{ ClosePopUp(); } } />
                </div>
            </>
        : <React.Fragment></React.Fragment>)
    );
}