import './Signature.css';

export default function Signature(props){

    // return(
    //     <div className={ "div_signature sig_" + props.position } style={ { color: props.color } }>
    //         <div className="sig_origin">{ props.origin }</div>
    //         <div className="sig_name">{ props.name }</div>
    //     </div>
    // )
}