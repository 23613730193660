import { useState, useEffect } from "react";

import './Home.css';

import { GetDataPage } from "interface/Data";

import Model_Home from "components/Model/Home";
import Model_Depositions from "components/Model/Depositions";
import Model_StudentWorks from "components/Model/StudentWorks";
import Model_TeacherProfile from "components/Model/TeacherProfile";
import Model_ModulesWorkload from "components/Model/ModulesWorkload";
import Model_VideoPresentation from "components/Model/VideoPresentation";
import Model_PersonalizedService from "components/Model/PersonalizedService";
import Model_ContentsExplanation from "components/Model/ContentsExplanation";

import Page_Payment from "../Payment";

export default function Page_Home(props){

    const [ dataPage, setDataPage ]       = useState(GetDataPage('home'));

    function CurrentPage(type, index, data){
        switch (type) {
            case "Home":
                return <Model_Home ReturnIndex={ props.ReturnIndex } CkickPage={ props.CkickPage } setLoading={ props.setLoading } key={ index } data={ data } />;

            case "Apresentação com vídeo":
                return <Model_VideoPresentation ReturnIndex={ props.ReturnIndex } CkickPage={ props.CkickPage } setLoading={ props.setLoading } key={ index } data={ data } />;

            case "Explicação dos conteúdos":
                return <Model_ContentsExplanation ReturnIndex={ props.ReturnIndex } CkickPage={ props.CkickPage } setLoading={ props.setLoading } key={ index } data={ data } />;

            case "Módulos e carga horária":
                return <Model_ModulesWorkload ReturnIndex={ props.ReturnIndex } CkickPage={ props.CkickPage } setLoading={ props.setLoading } key={ index } data={ data } />;

            case "Trabalhos de alunos":
                return <Model_StudentWorks ReturnIndex={ props.ReturnIndex } CkickPage={ props.CkickPage } setLoading={ props.setLoading } key={ index } data={ data } />;

            case "Atendimento personalizado":
                return <Model_PersonalizedService ReturnIndex={ props.ReturnIndex } CkickPage={ props.CkickPage } setLoading={ props.setLoading } key={ index } data={ data } />;

            case "Depoimentos":
                return <Model_Depositions ReturnIndex={ props.ReturnIndex } CkickPage={ props.CkickPage } setLoading={ props.setLoading } key={ index } data={ data } />;

            case "Perfil do professor":
                return <Model_TeacherProfile ReturnIndex={ props.ReturnIndex } CkickPage={ props.CkickPage } setLoading={ props.setLoading } key={ index } data={ data } />;
        }
    }

    useEffect(()=>{        
        document.title = 'Crazy Pixel School';
    }, []);

    return(
        <div className="Page_Home">
            {
                dataPage.map((elem, index)=>{
                    return CurrentPage(elem.type, index, elem)
                })
            }
            {
                GetDataPage('payment').show_home == false ? null :
                <Page_Payment ReturnIndex={ props.ReturnIndex } CkickPage={ props.CkickPage } setLoading={ props.setLoading } origin="home" />
            }
        </div>
    )
}