import { useState, useEffect } from "react";

import './MySignature.css';

import { SetListPag } from "interface/Page";
import { GetDataPage } from "interface/Data";
import { GetUserData } from "interface/Users";

import { Link } from "react-router-dom";

import { SvgCheked } from "components/SvgFile";

import { SetModalData, SetModalState } from "interface/PopUp";

export default function Model_MySignature(props){

    const [ dataPage, setDataPage ] = useState(GetDataPage('payment')); 
    const [ category, setCategory ] = useState(GetDataPage('category_payment'));

    const [ showData, setShowData ] = useState(InitialData());

    function InitialData(){
        const newData = dataPage.payment.find(item => item.id_nivel == GetUserData('user').nivel);
        return newData
    }

    return(
        <div className="Model_MySignature">
            <div className="container">
                <div className="data_signature">     
                    <div className="details">
                        <div className="type_data">Tipo de adesão</div>
                        <div className="type_name">{ showData.type }</div>
                    </div>
                    <div className="details">
                        <div className="type_data">Nível</div>
                        <div className="type_name">{ GetUserData('user').nivel_page }</div>
                    </div>
                    <div className="details">
                        <div className="type_data">Valor R$</div>
                        <div className="type_name">
                            { 
                                showData.type == 'Free' ? "0,00" : 
                                    showData.type == "Assinatura" ? showData.cash.toLocaleString('pt-br', { minimumFractionDigits: 2 }) + " por mês" : 
                                    (showData.cash * 12).toLocaleString('pt-br', {minimumFractionDigits: 2})
                            }
                        </div>
                    </div>
                    {
                        GetUserData('user').nivel == 3 ? 
                        <div className="btn_alt">  
                            <div className="btn_name" onClick={ ()=>{ SetModalState('PaymentVoucher', true); } }>
                                Adicionar comprovante
                            </div>
                        </div> :
                        <div className="btn_alt">                            
                            <Link to="/mentoring" onClick={ ()=>{ SetListPag('page', "mentoring"); } }>
                                <div className="btn_name">
                                    Atualizar assinatura
                                </div>
                            </Link>
                        </div>
                    }
                </div>

                <div className="list_category">
                    <div className="title">Está incluso na adesão</div>
                    <div className="div_show_category">
                        {
                            showData.category.map((elem, index)=>{
                                return(
                                    <div className="show_category" key={ index }>
                                        <div className="icon_category">
                                            <SvgCheked className="icons" color="#5D9200" />
                                        </div>
                                        <div className="name_category">
                                            { elem.name }
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}