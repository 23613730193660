import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

import Axios from 'axios';

import { SetListData } from 'interface/Data';

const root = ReactDOM.createRoot(document.getElementById('home'));

const data = new FormData();
data.append('list_inf', 'show_data');

Axios({
    url     :  process.env.REACT_APP_API_URL + 'php/list_data.php',
    mode    : 'no-cors',
    method  : 'POST',
    data    : data
}) 
.then(response => {
    console.log(response.data);
    SetListData(response.data);

    root.render(
        <React.Fragment>
            <App />
        </React.Fragment>
    ); 
})