import { useState, useEffect, useRef } from "react";

import './Payment.css';

import Axios from 'axios';

import Cookies from 'universal-cookie';

import { GetDataPage } from "interface/Data";
import { GetUserData } from "interface/Users";
import { SetModalData, SetModalState } from "interface/PopUp";
import { GetListPag, RegisterListPag, SetListPag } from "interface/Page";

import { cookie_email, cookie_passw } from "dataFixed";

import Page_Payment from "components/Page/Payment";

import Model_Login from "../Login";
import Model_Register from "../Register";
import { Access } from "services/Login";

export default function Model_Payment(props){
    const cookies = new Cookies();

    const heightIframe = useRef();

    const [ showData, setShowData ] = useState(GetDataPage('settings'));

    const [ dataPage, setDataPage ] = useState(GetDataPage('payment'));
    const [ category, setCategory ] = useState(GetDataPage('category_payment')); 
    
    const [ typePayment, setTypePayment ] = useState(GetListPag('typePayment'));

    const [ stage, setStage ] = useState(0);

    const [ showDetails, setShowDetails ]   = useState(InitialData('all'));
    const [ listCategory, setListCategory ] = useState(InitialData('category'));

    const [ linkPayment, setLinkPayment ] = useState('');

    function InitialData(type){
        if(typePayment != ""){
            const newData = dataPage.payment.find(item => item.id == typePayment);
            if(type == 'all'){
                return newData;
            }else {
                return newData['category'];
            }
        }
        if(type == 'category'){
            return [];
        }
        return "";
    }

    function LoadingLinkPayment(){        
        if(showDetails.type !='Free'){

            props.setLoading(true);
            const data = new FormData();
            data.append('type', typePayment);
            setTimeout(() => {
                data.append('return_pass', cookies.get(cookie_passw));
                data.append('return_email', cookies.get(cookie_email));

                Axios({
                    url     :  process.env.REACT_APP_API_URL + 'php/MercadoPago/process_payment.php',
                    mode    : 'no-cors',
                    method  : 'POST',
                    data    : data
                }) 
                .then(response => {
                    props.setLoading(false);
                    setLinkPayment(response.data);
                })
            }, 600);
            

            // const loginChecked = setInterval(() => {
            // }, 100);
        }
    }

    function CallbackSuccess(){
        LoadingLinkPayment();
        props.RegisterAccess(true);
        setStage(2);
    }

    function Logado(){
        LoadingLinkPayment();
        props.RegisterAccess(true);
        setStage(2);
    }

    function NextStage(){
        if(Object.keys(GetUserData('user')).length > 0){
            LoadingLinkPayment();
            setStage(2);
        }else {
            setStage(1);
        }
    }

    function CurrentData(){
        switch (stage) {
            case 0:
                return(
                    <>                    
                        <div className="title">Informações sobre a compra</div>
                        <div className="list_inf_payment">
                            <div className="show_details">
                                <div className="payment_title">
                                    <div className="" style={ { color: "#ffffff" } }>
                                        { showDetails.title }
                                    </div>
                                    {
                                        showDetails.type == 'Free' ? null :
                                        <div className="payment_show_cash">
                                            <div className="payment_sigla">
                                                R$
                                            </div>
                                            <div className="payment_cash">{ showDetails.cash }</div>
                                            <div className="payment_data">
                                                {
                                                    showDetails.type == 'Compra' ? "12x" : "/" + showDetails.type_cash 
                                                }
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div className="payment_subtitle" style={ { color: showDetails.color } }>
                                    { showDetails.subtitle }
                                </div>
                                <div className="list_category">
                                    {
                                        listCategory.map((elem, index)=>{
                                            return(
                                                <div className="category_name" key={ index }>
                                                    { elem.name }
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            <div className="div_next">
                                <div className="show_inf">
                                    {
                                        showDetails.type == 'Compra' ? 
                                        <>
                                            <div className="details_title">Detalhes da compra</div>
                                            
                                            <div className="details_inf" dangerouslySetInnerHTML={ { __html: showData.purchase.replaceAll('&#34;', '"') } } />
                                        </> :
                                        showDetails.type == 'Free' ? 
                                        <>                                  
                                            <div className="details_title">Assinatura sem custo</div>     
                                        </> :
                                        <>                                            
                                            <div className="details_title">Detalhe da sua assinatura</div>
                                            
                                            <div className="details_inf" dangerouslySetInnerHTML={ { __html: showData.text.replaceAll('&#34;', '"') } } />
                                        </>
                                    }                            
                                    {/* <div className="details_inf">
                                        Clique no botão abaixo para ser redirecionado para página de pagamento.
                                    </div> */}

                                    <div className="details_cancel">
                                        Para cancelamento ou demais informações favor entrar em contato.
                                    </div>
                                </div>
                                <div className="div_btn">                                 
                                    <div className="button_text" onClick={ ()=>{ NextStage() } }>
                                        Continuar
                                    </div>
                                </div>
                                <div className="div_btn">
                                    <div className="button_text button_return" onClick={ ()=>{ setStage(0); SetListPag('typePayment', ''); } }>
                                        Voltar
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )
            case 1:
                return(
                    <>
                        <div className="title">É necessário fazer login para continuar</div>
                        <div className="div_data_login">
                            <div className="div_register">
                                <Model_Register ReturnIndex={ props.ReturnIndex } CkickPage={ props.CkickPage } setLoading={ props.setLoading } CallbackSuccess={ CallbackSuccess } origin="payment" />
                            </div>
                            <div className="div_login">
                                <Model_Login ReturnIndex={ props.ReturnIndex } CkickPage={ props.CkickPage } setLoading={ props.setLoading } CallbackSuccess={ CallbackSuccess } origin="payment" />
                            </div>
                        </div>
                    </>
                )
            case 2:
                return(
                    <>
                        <div className="title">Escolha a forma de pagamento</div>
                        <div className="show_payment">
                            <iframe ref={ heightIframe } id="iframe_payment" className="div_payment" src={ linkPayment } allowFullScreen />
                        </div>
                    </>
                )
        }
    }

    useEffect(()=>{
        RegisterListPag('typePayment', setTypePayment);
    }, []);

    useEffect(()=>{
        setShowDetails(InitialData('all'));
        setListCategory(InitialData('category'));
    }, [typePayment]);
    
    return(
        typePayment == "" ?  <Page_Payment ReturnIndex={ props.ReturnIndex } CkickPage={ props.CkickPage } setLoading={ props.setLoading } origin="home" /> :
        <div className="Model_Payment">
            <div className="container">
                { CurrentData() }
            </div>
        </div>
    )
}