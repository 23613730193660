import { useState, useEffect } from "react";

import './Login.css';

import { SetListPag } from "interface/Page";
import { SetModalState } from "interface/PopUp";

import { Link } from "react-router-dom";

import { SvgLogotipo } from "components/SvgFile";

import Model_Login from "components/Model/Login";

export default function Page_Login(props){

    useEffect(()=>{        
        document.title = 'Login';
    }, []);

    return(
        <div className="Page_Login">
            <div className="logotipo">
                <SvgLogotipo widthLogo="width_logo" className="svg_logotipo" />
            </div>
            <div className="div_login">
                <Model_Login ReturnIndex={ props.ReturnIndex } CkickPage={ props.CkickPage } setLoading={ props.setLoading } origin="login" CallbackSuccess={ props.CallbackSuccess } />
            </div>
        </div>
    )
}