import { useState, useEffect } from "react";

import './Footer.css';

import { SvgLogotipo } from "components/SvgFile";

import { GetDataPage } from "interface/Data";

export default function Footer(props){
    
    const [ showData, setShowData ] = useState(GetDataPage('settings'));

    return(
        <footer className="Footer">
            <div className="div_top">      
                <div className="container div_end_flex">
                    <div className="div_left">
                        <div className="footer_div_logotipo">
                            <img src="./assets/logo.png" className="footer_logotipo" />
                        </div> 
                        <div className="list_social_network">
                            <div className="">
                                <div className="">Redes Sociais</div>  
                            </div>
                            <div className="div_list_rede_social">
                                {
                                    showData.instagram == "" ? null : 
                                    <a href={ showData.instagram } target="_blank">              
                                        <div className="bg_icon">
                                            <img alt="instagram" src="./assets/icons_svg/insta.svg" className="icons_footer" />
                                        </div>
                                    </a>
                                }

                                {
                                    showData.youtube == "" ? null : 
                                    <a href={ showData.youtube } target="_blank">               
                                        <div className="bg_icon">
                                            <img alt="youtube" src="./assets/icons_svg/youtube.svg" className="icons_footer" />
                                        </div>
                                    </a>
                                }

                                {
                                    showData.facebook == "" ? null : 
                                    <a href={ showData.facebook } target="_blank">               
                                        <div className="bg_icon">
                                            <img alt="facebook" src="./assets/icons_svg/face.svg" className="icons_footer" />
                                        </div>
                                    </a>
                                }

                                {
                                    showData.twitter == "" ? null : 
                                    <a href={ showData.twitter } target="_blank">               
                                        <div className="bg_icon">
                                            <img alt="twitter" src="./assets/icons_svg/twitter.svg" className="icons_footer" />
                                        </div>
                                    </a>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="div_right">
                        <div className="div_menu_site">
                            <div className="space_footer text_left">
                                {
                                    showData.privacy_terms == "" ? 
                                    <div className="name_menu">Política de cookies</div> :
                                    <div className="name_menu">
                                        <a href={ showData.privacy_terms } target="_blank">
                                            Política de cookies
                                        </a>
                                    </div>
                                }
                                {
                                    showData.cookie_policy == "" ? 
                                    <div className="name_menu">Política de privacidade</div> :
                                    <div className="name_menu">
                                        <a href={ showData.cookie_policy } target="_blank">
                                            Política de privacidade
                                        </a>
                                    </div>
                                }
                                <div className="name_menu">Professores</div>
                            </div>   
                            <div className="space_footer">
                                <a href="/#index" className="page_site" id="page_site" onClick={ ()=>{ props.CkickPage('index'); props.animateScroll() } }>
                                    <div className="name_menu">Home</div>
                                </a>
                                <a href="/#howworks" className="page_site" onClick={ ()=>{ props.CkickPage('howworks'); } }>
                                    <div className="name_menu">Como funciona</div>
                                </a>
                                <a href="/#courses" className="page_site" onClick={ ()=>{ props.CkickPage('courses'); } }>
                                    <div className="name_menu">Cursos</div>
                                </a>
                            </div> 
                            <div className="space_footer text_right">
                                <a href="/#mentoring" className="page_site" onClick={ ()=>{ props.CkickPage('mentoring'); } }>
                                    <div className="name_menu">Mentoria</div>
                                </a>
                                <a href="/#rolo" className="page_site" onClick={ ()=>{ props.CkickPage('rolo'); } }>
                                    <div className="name_menu">Rolo</div>
                                </a>
                                <a href="/#blog" className="page_site" onClick={ ()=>{ props.CkickPage('blog'); } }>
                                    <div className="name_menu">Blog</div>
                                </a>
                            </div> 
                        </div>
                        <div className="footer_payment">
                            <div className="">
                                <img alt="payment" src="./assets/icons_svg/site-seguro.svg" className="icons_payment" /> 
                            </div>
                            <div className="">
                                <div className="">Forma de pagamento</div>
                                <div className="div_list_icons">
                                    <div className="">
                                        <img alt="payment" src="./assets/icons_svg/cartao.svg" className="icons_type_payment" /> 
                                    </div>
                                    <div className="">
                                        <img alt="payment" src="./assets/icons_svg/pix.svg" className="icons_type_payment" />
                                    </div>
                                    <div className="">
                                        <img alt="payment" src="./assets/icons_svg/boleto.svg" className="icons_type_payment" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="div_end">
                <div className="container div_end_flex">
                    {
                        showData.address == "" ? <div className="" /> : 
                        <div dangerouslySetInnerHTML={ { __html: showData.address.replaceAll('&#34;', '"') } } />
                    }
                    <div className="">
                        © 2017 - 2023 Crazy Pixel School LTDA - CNPJ: 45.033.422/0001-00
                    </div>
                </div>
            </div>
        </footer>
    )
}