import { useState, useEffect } from "react";

import './StudentWorks.css';
import Signature from "components/Signature";

export default function Model_StudentWorks(props){

    const [ dataPage, setDataPage ] = useState(props.data);

    useEffect(()=>{        
        // if(document.body.clientWidth <= 910){
        //     const checkedDiv = setInterval(() => {
        //         if(document.getElementById('StudentWorks_Bg')){
        //             document.getElementById('StudentWorks_Bg').style.display = 'none';
        //             clearInterval(checkedDiv);
        //         }                
        //     }, 100);
        // }

        // window.addEventListener('resize', function () {
        //     if(window.innerWidth <= 910){
        //         this.document.getElementById('StudentWorks_Bg').style.display = 'none'; 
        //     }else {
        //         this.document.getElementById('StudentWorks_Bg').style.display = null;
        //     }
        // });
    }, []);

    return(
        <div className="Model_StudentWorks" style={ { background: dataPage.bg_color } }>
            <div className="container">
                <div className="show_video">
                    {
                        dataPage.title_1 ? <div className="title">{ dataPage.title_1 }</div> : null
                    }  
                    {
                        dataPage.video_type == "Vimeo" ?
                        <iframe className="div_video" src={ "https://player.vimeo.com/video/" + dataPage.video_code + "?color=ffffff&amp;title=0&amp;byline=0&amp;portrait=0" } frameBorder="0" webkitallowfullscreen="" mozallowfullscreen="" allowFullScreen="" />
                        :
                        <iframe className="div_video" src={ "https://www.youtube.com/embed/" + dataPage.video_code + "?color=ffffff&amp;title=0&amp;byline=0&amp;portrait=0" } frameBorder="0" webkitallowfullscreen="" mozallowfullscreen="" allowFullScreen="" />
                    } 
                </div>
            </div>

            <Signature origin={ "origin" } name={ "Name" } position="left" color="#ffffff" />

            <div className="background_image" id="StudentWorks_Bg">
                <img alt="img" src={ dataPage.bg_img } className="img_home" />
            </div>
        </div>
    )
}