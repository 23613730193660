import { useState, useEffect } from "react";

import './ContentsExplanation.css';

import { SvgPdf } from "components/SvgFile";

import Signature from "components/Signature";

export default function Model_ContentsExplanation(props){

    const [ dataPage, setDataPage ] = useState(props.data);

    return(
        <div className="Model_ContentsExplanation" style={ { background: dataPage.bg_color } }>
            <div className="container">
                <div className="div_text">
                    {
                        dataPage.title_1 !='' ? 
                        <div className="div_title">
                            <div className="title">{ dataPage.title_1 }</div>
                        </div> : null
                    }
                    {
                        dataPage.text_1 !='' ? <div className="text" dangerouslySetInnerHTML={ { __html: dataPage.text_1.replaceAll('&#34;', '"') } } /> : null
                    }
                    {
                        dataPage.title_2 !='' ? <div className="subtitle">{ dataPage.title_2 }</div> : null
                    }                    
                    {
                        dataPage.text_2 !='' ? <div className="text" dangerouslySetInnerHTML={ { __html: dataPage.text_2.replaceAll('&#34;', '"') } } /> : null
                    }
                    {
                        dataPage.title_3 !='' ? <div className="subtitle">{ dataPage.title_3 }</div> : null
                    }     
                    {
                        dataPage.text_3 !='' ? <div className="text" dangerouslySetInnerHTML={ { __html: dataPage.text_3.replaceAll('&#34;', '"') } } /> : null
                    }
                    {
                        dataPage.btn_1_name !='' || dataPage.btn_2_name !='' ?
                        <div className="file">
                            <a href={ dataPage.file } className="show_file" target="_blank">
                                <div className="show_icons">
                                    <SvgPdf color="#FF5200" className="pdf" />
                                </div>
                                <div className="text_pdf">
                                    { 
                                        dataPage.btn_1_name !='' ? <div className="">{ dataPage.btn_1_name }</div> : null
                                    }
                                    { 
                                        dataPage.btn_2_name !='' ? <div className="">{ dataPage.btn_2_name }</div> : null
                                    }                              
                                </div>
                            </a>
                        </div> : null
                    }
                </div>

                {
                    dataPage.img_example == "" ? null :
                    <div className="list_img">
                        <img alt="01" src={ dataPage.img_example } className="img" loading="lazy" />
                        <Signature origin={ "origin" } name={ "Name" } position="right" color="#ffffff" />
                    </div>
                }
            </div>
        </div>
    )
}