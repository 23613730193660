import React, { useState, useEffect } from "react";

import './AddPaymentVoucher.css';

import { RegisterModalObserver, SetModalData, SetModalState } from "interface/PopUp";

import { SvgClose, SvgDelete } from "components/SvgFile";

import { Reg_PaymentVoucher } from "services/Login";

export default function PopUp_PaymentVoucher(props){
    
    const [ showPopUp, setShowPopUp ] = useState(false);
    const [ msgReturn, setMsgReturn ] = useState('');

    const [ file, setFile ]     = useState('');
    const [ status, setStatus ] = useState(false);
    const [ date, setDate ]     = useState('');

    function Register(event){
        event.preventDefault();
        props.setLoading(true);
        Reg_PaymentVoucher(date, file, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function AddFile(value){
        if(value){
            setFile(value);
            setStatus(true);
        }else {
            setFile('');
            setStatus(false);
        }
    }

    function CallbackSuccess(){
        props.setLoading(false);
        setMsgReturn('Comprovante enviado com sucesso!');
    }

    function CallbackError(){
        props.setLoading(false);
        setMsgReturn('Erro ao adicionar comprovante, tente novamente!');
    }

    function ClosePopUp(){  
        setMsgReturn('');    
        setFile('');
        setDate('');  
        SetModalState('PaymentVoucher', false);
    }
  
    useEffect(()=>{
        RegisterModalObserver('PaymentVoucher', setShowPopUp);
    }, []);

    return (
        (
            showPopUp ? 
            <form onSubmit={ Register }>
                <div className="PopUp">
                    <div className="all payment_voucher">
                        <div className="div_data">                         
                            <div className="content">
                                <div className="div_title">
                                    <div className="title_popup">
                                        {
                                            msgReturn == "" ? "Adicionar comprovante" : "Pagamento Adicionado"
                                        }
                                    </div>
                                    <div className="">
                                        <a onClick={ ()=>{ ClosePopUp() } }>
                                            <SvgClose color="#F00000" className="icons" />
                                        </a>
                                    </div>
                                </div>
                                {
                                    msgReturn == "" ? 
                                    <>
                                        <div className="div_input">                                            
                                            <div className="dash_div_input">
                                                <input type="date" onChange={ (e)=>{ setDate(e.target.value) } } value={ date } required />
                                                <span className="name_input">Data</span>
                                            </div>
                                    
                                            <label>
                                                <input type="file" onChange={ (e)=>{ AddFile(e.target.files[0]); } } accept="image/*"/>
                                                <div className={ status ? "div_file file_add" : "div_file" }>
                                                    { status ? "Arquivo adicionado" : "Adicionar arquivo" }
                                                </div>
                                            </label>
                                            <div className="div_remuve" onClick={ ()=>{ AddFile('') } }>
                                                <SvgDelete className="icons" color="#ffffff" />
                                            </div>
                                        </div>
                                        <div className="button_popup">
                                            <button className="button_save_popup btn_save">
                                                Enviar
                                            </button>
                                        </div>
                                    </>
                                    :
                                    <div className="div_button">
                                        <div className="show_msg">
                                            { msgReturn }
                                        </div>
                                        <div className="button_popup return_resposta">
                                            <div className="button_save_popup" onClick={ ()=>{ ClosePopUp() } }>
                                                Fechar
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div> 
                    </div>
                </div>
            </form>
            : <React.Fragment></React.Fragment>
        )
    )
}