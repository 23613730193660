import { useState, useEffect } from "react";

import './HowWorks.css';

import { GetDataPage } from "interface/Data";

import { SvgTriagle } from "components/SvgFile";

export default function Page_HowWorks(props){

    const [ dataPage, setDataPage ]     = useState(GetDataPage('howworks'));
    const [ listDoubts, setListDoubts ] = useState(dataPage.doubts);

    function OpenDoubts(index, status){
        const newData = [...listDoubts];
        newData.map((elem, index_1)=>{
            let accordion = document.querySelector('.accordion_' + index_1);
            if(index_1 == index){                
                if(status == true){
                    accordion.style.maxHeight = accordion.scrollHeight + "px";
                    accordion.style.padding   = "12px 20px 20px";
                    elem.status = true;  
                }else {
                    accordion.style.maxHeight = null;
                    accordion.style.padding   = "12px 20px 0px";  
                    elem.status = false;  
                }
            
            }else {
                accordion.style.maxHeight = null;
                accordion.style.padding   = "12px 20px 0px";  
                elem.status = false;  
            }

        })
        setListDoubts(newData);
    }

    useEffect(()=>{
        document.title = 'Como funciona';
    }, []);

    return(
        <div className="Page_HowWorks">
            <div className="container">
                <div className="title">{ dataPage.title_1 }</div>
                <div className="text" dangerouslySetInnerHTML={ { __html: dataPage.text } } />
                <div className="line_color" />
                <div className="title">{ dataPage.title_2 }</div>
                <div className="list_doubts">
                    {
                        listDoubts.map((elem, index)=>{
                            return(
                                <div key={ index }>
                                    <div className="doubts" onClick={ ()=>{ OpenDoubts(index, !elem.status) }}>
                                        <div className="triagle">
                                            {
                                                elem.status == true ?
                                                <SvgTriagle color="#FFFFFF" className="icons_active" /> :
                                                <SvgTriagle color="#FFFFFF" className="icons_" />
                                            }
                                        </div>
                                        <div className="">{ elem.question }</div>
                                    </div>
                                    <div className={ "response accordion_" + index } dangerouslySetInnerHTML={ { __html: elem.response.replaceAll('&#34;', '"') } } />
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}