import { useState, useEffect } from "react";

import './Home.css';

import { useNavigate } from "react-router-dom";

import { SetListPag } from "interface/Page";

export default function Model_Home(props){

    const [ dataPage, setDataPage ] = useState(props.data);
    const navigate = useNavigate();

    function OpenClick(value){
        navigate("/" + value);
        SetListPag('page', value);  
    }

    function ShowButton(type, destiny, title){
        switch (type) {
            case 'Interno':
                return(
                    <div onClick={ ()=>{ OpenClick('mentoring') }}>
                        <div className="button_text">
                            { title }
                        </div>
                    </div>
                )
                
            case 'Externo':
                return(
                    <a href={ destiny } onClick={ ()=>{ props.CkickPage(destiny) } }>
                        <div className="button_text">
                            { title }
                        </div>
                    </a>
                )
        }
    }

    return(
        <div className="Model_Home">
            <div className="show_text">
                {
                    dataPage.title_1 ? <div className="title">{ dataPage.title_1 }</div> : null
                }                
                <div className="div_highlight">
                    {
                        dataPage.title_2 ? <div className="text">{ dataPage.title_2 }</div> : null
                    }
                    {
                        dataPage.title_3 ? <div className="highlight">{ dataPage.title_3 }</div> : null
                    }                   
                    <div className="list_text">
                        {
                            dataPage.title_4 ? <div className="text">{ dataPage.title_4 }</div> : null
                        }  
                        {
                            dataPage.title_5 ? <div className="text">{ dataPage.title_5 }</div> : null
                        }  
                        {
                            dataPage.title_6 ? <div className="text">{ dataPage.title_6 }</div> : null
                        }  
                    </div>
                </div>
                <div className="button">
                    {
                        dataPage.btn_1_name == '' ? null : ShowButton(dataPage.btn_1_type, dataPage.btn_1_destiny, dataPage.btn_1_name)
                    }
                    {
                        dataPage.btn_2_name == '' ? null : ShowButton(dataPage.btn_2_type, dataPage.btn_2_destiny, dataPage.btn_2_name)
                    }
                </div>
            </div>
            <div className="background_image" style={ { backgroundImage: 'url("' + dataPage.bg_img + '")' } }>
            </div>
        </div>
    )
}