import { useState, useEffect } from "react";

import './ModulesWorkload.css';

import Signature from "components/Signature";

export default function Model_ModulesWorkload(props){

    const [ dataPage, setDataPage ] = useState(props.data);

    return(
        <div className="Model_ModulesWorkload" style={ { background: dataPage.bg_color } }>
            <div className="container">
                <div className="bg_img">
                    <img alt="02" src={ dataPage.img_example } className="img" />
                    <Signature origin={ "origin" } name={ "Name" } position="left" color="#ffffff" />
                </div>
                <div className="div_text">
                    <div className="title">{ dataPage.title_1 }</div>
                    <div className="table">
                        <table width="100%" cellPadding="2" cellSpacing="6">
                            <tbody>
                                {
                                    dataPage.contents.map((elem, index)=>{
                                        return(
                                            <tr key={ index }>
                                                <td className="table_numb" style={ { backgroundColor: elem.text } }>{ index + 1 }</td>
                                                <td className="table_text" style={ { color: elem.text } }>{ elem.name }</td>
                                                <td className="table_time" style={ { backgroundColor: elem.text } }>{ elem.date }h</td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                    {
                        dataPage.text_1 ? 
                        <div className="time">
                            <div className="" dangerouslySetInnerHTML={ { __html: dataPage.text_1.replaceAll('&#34;', '"') } } />
                        </div> : null
                    }
                </div>
            </div>
        </div>
    )
}