// to the website
let DataPage = {
    "home"             : [],
    "rolo"             : [],
    "course"           : [],
    "payment"          : {},
    "recover"          : [],
    "settings"         : {},
    "menuName"         : {},
    "howworks"         : {},
    "mentoring"        : [],
    "list_category"    : [],
    "category_payment" : [],
    "course_questions" : []
};

let NotifyDataPage = {
    "home"             : [],
    "rolo"             : [],
    "course"           : [],
    "payment"          : [],
    "recover"          : [],
    "settings"         : [],
    "menuName"         : [],
    "howworks"         : [],
    "mentoring"        : [],
    "list_category"    : [],
    "category_payment" : [],
    "course_questions" : []
}

export function SetListData(value) {
    DataPage = value;
    NotifyDataPage["home"].forEach(element => {             element(value); });
    NotifyDataPage["rolo"].forEach(element => {             element(value); });
    NotifyDataPage["course"].forEach(element => {           element(value); });
    NotifyDataPage["payment"].forEach(element => {          element(value); });
    NotifyDataPage["recover"].forEach(element => {          element(value); });
    NotifyDataPage["settings"].forEach(element => {         element(value); });
    NotifyDataPage["menuName"].forEach(element => {         element(value); });
    NotifyDataPage["howworks"].forEach(element => {         element(value); });
    NotifyDataPage["mentoring"].forEach(element => {        element(value); });
    NotifyDataPage["list_category"].forEach(element => {    element(value); });
    NotifyDataPage["category_payment"].forEach(element => { element(value); });
    NotifyDataPage["course_questions"].forEach(element => { element(value); });
}

export function SetListDataSingle(key, value) {
    DataPage[key] = value;
    NotifyDataPage[key].forEach(element => {
        element(value);
    });
}

export function GetDataPage(key){
    return DataPage[key];
}

export function GetDataPageID(key, index){
    return DataPage[key][index];
}

export function RegisterDataPage(key, value){
    NotifyDataPage[key].push(value);
}

export function UnRegisterDataPage(key, callback){
    if(NotifyDataPage[key].length > 0){
        NotifyDataPage[key] = NotifyDataPage[key].filter((item) => {
            return item !== callback;
        });
    }
}