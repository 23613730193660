import { useState, useEffect } from "react";

import './TeacherProfile.css';

import Signature from "components/Signature";

export default function Model_TeacherProfile(props){

    const [ dataPage, setDataPage ] = useState(props.data);

    function ShowDataProfile(value){
        return(
            <div className="show_data">                
                <div className="name_profile">                        
                    <div className="">
                        <img alt="" src={ dataPage.file } className="img_profile" />
                    </div>      
                    <div className="div_name">
                        <span className="type_name">{ dataPage.btn_1_name }</span>
                        <span className="subname">{ dataPage.btn_2_name }</span>
                    </div>
                </div>
                <div className="text" dangerouslySetInnerHTML={ { __html: dataPage.text_1.replaceAll('&#34;', '"') } } />
                <div className="portfolio text">             
                    <div className="">Portfólio</div>  
                    {
                        dataPage.contents.map((key, index)=>{
                            return(         
                                <div className="" key={ index }>
                                    <a href={ key.text } className="show_a_icon" target="_blank">
                                        <img alt="" src={ key.file } className="icons_" />
                                    </a>
                                </div>  
                            )
                        })
                    }   
                </div>
            </div>
        )
    }

    function ShowDataFile(position){
        return(
            <div className="file">    
                <img alt="" src={ dataPage.img_example } className="img_bg" />
                <Signature origin={ "origin" } name={ "Name" } position={ position } color="#ffffff" />
            </div>
        )
    }

    useEffect(()=>{        
        // if(document.body.clientWidth <= 910){
        //     const checkedDiv = setInterval(() => {
        //         if(document.getElementById('TeacherProfile_Bg')){
        //             document.getElementById('TeacherProfile_Bg').style.display = 'none';
        //             clearInterval(checkedDiv);
        //         }                
        //     }, 100);
        // }

        // window.addEventListener('resize', function () {
        //     if(window.innerWidth <= 910){
        //         this.document.getElementById('TeacherProfile_Bg').style.display = 'none'; 
        //     }else {
        //         this.document.getElementById('TeacherProfile_Bg').style.display = null;
        //     }
        // });
    }, []);

    return(
        <div className="Model_TeacherProfile" style={ { background: dataPage.bg_color } }>
            <div className="container">
                <div className="show_profile">
                    {
                        dataPage.line_up == "right" ? 
                        <>
                            { ShowDataFile('left') }
                            { ShowDataProfile(dataPage.line_up) }
                        </> :   
                        <>
                            { ShowDataProfile(dataPage.line_up) }
                            { ShowDataFile('right') }
                        </>   
                    }
                </div>
            </div>
            <div className="background_img" style={ { backgroundImage: 'url("' + dataPage.bg_img + '")' } } />
        </div>
    )
}