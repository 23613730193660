import { useState, useEffect } from "react";

import './Payment.css';

import { Link } from "react-router-dom";

import { GetDataPage } from "interface/Data";
import { SetListPag } from "interface/Page";

export default function Page_Payment(props){

    const [ dataPage, setDataPage ] = useState(GetDataPage('payment'));
    const [ category, setCategory ] = useState(GetDataPage('category_payment'));    

    function OpenClick(value, type){
        props.ReturnIndex();
        SetListPag('typePayment', type);
        SetListPag('page', value);
    }

    useEffect(()=>{
        document.title = 'Planos de assinatura';
    }, []);

    return(
        <div className="Page_Payment">
            <div className="container">
                <div className="title">{ dataPage.title }</div>
                <div className="subtitle">{ dataPage.subtitle }</div>
                <div className="list_table">
                    <table width="100%" cellPadding="0" cellSpacing="0">
                        <tbody>   
                            <tr> 
                                <td className="space_white"></td>
                                {
                                    dataPage.payment.map((elem, index)=>{
                                        return(
                                            <td className="td_payment" key={ index }>
                                                <div className="list_inf_payment">
                                                    <div className="list_title" style={ { color: elem.color } }>    
                                                        {
                                                            elem.best_cost == "Não" ? <div className="best_choice"></div> :
                                                            <div className="best_choice best_choice_active">
                                                                melhor custo benefício
                                                            </div>
                                                        }       
                                                        { elem.title }
                                                        <div className="list_subtitle">
                                                            { elem.subtitle }
                                                        </div>
                                                    </div>
                                                    <div className="show_payment">
                                                        {
                                                            elem.type == "Free" ? 
                                                            <div className="payment_cash">Free</div> : 
                                                            <div className="show_type_payment">
                                                                <div className="payment_sigla">R$</div>
                                                                <div className="payment_cash">{ elem.cash }</div>
                                                                <div className="payment_data">
                                                                    <span className="align_botton_type">
                                                                        /
                                                                        {   
                                                                            elem.type == "Assinatura" ?
                                                                                elem.type_cash : "12x"
                                                                        }
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                    <div className="button">
                                                        <Link to="/payment" onClick={ ()=>{ OpenClick('payment', elem.id); } } key={ index }>
                                                            <div className="button_access">
                                                                Comece agora
                                                            </div>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </td>
                                        )
                                    })
                                }
                            </tr>
                            {
                                category.map((elem, index)=>{                                    
                                    return(
                                        <tr key={ index }>
                                            <td className="space_names">{ elem.name }</td>
                                            {
                                                dataPage.payment.map((elem_1, index_1)=>{
                                                    let opt_selected = '';
                                                    elem_1.category.map((elem_2, index_2)=>{
                                                        if(elem_2.id_category == elem.id){
                                                            opt_selected = 1;
                                                        }
                                                    });
                                                    return(                                
                                                        <td className="td_payment" key={ index_1 }>
                                                            <div className="payment_circle">
                                                                {
                                                                    opt_selected ? <div className="payment_active"/> : null
                                                                }
                                                            </div>
                                                        </td>
                                                    )
                                                })
                                            }
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
                <div className="list_mobile">
                    {
                        dataPage.payment.map((elem, index)=>{
                            return(
                                <div className="show_payment_mobile" key={ index }>
                                    <div className="payment_mobile">
                                        {
                                            elem.best_cost == "Não" ? null : 
                                            <div className="mobile_active">
                                                melhor custo benefício
                                            </div>
                                        } 
                                        <div className="payment_type_mobile">
                                            {
                                                elem.type == "Free" ?
                                                <>                                                
                                                    <div className="mobile_title" style={ { color: elem.color } }>
                                                        { elem.title }
                                                    </div>                                       
                                                    <div className="mobile_subtitle">
                                                        { elem.subtitle }
                                                    </div>
                                                </> : 
                                                <>                                                 
                                                    <div className="mobile_title" style={ { color: elem.color } }>
                                                        { elem.title }
                                                    </div>                                    
                                                    <div className="mobile_subtitle show_type_payment">
                                                        <div className="payment_sigla">R$</div>
                                                        <div className="payment_cash">{ elem.cash }</div>
                                                        <div className="payment_data">
                                                            <span className="align_botton_type">
                                                                /
                                                                {   
                                                                    elem.type == "Assinatura" ?
                                                                        elem.type_cash : "12x"
                                                                }
                                                            </span>
                                                        </div>
                                                    </div>  
                                                </>
                                            }
                                        </div>
                                        {
                                            elem.type != "free" ? null :
                                            <div className="mobile_subtitle">
                                                {  elem.subtitle }
                                            </div>
                                        }
                                        <div className="button">
                                            <Link to="/payment" onClick={ ()=>{ OpenClick('payment', elem.id); } } key={ index }>
                                                <div className="button_text">
                                                    Comece agora
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="list_category_mobile">
                                        {
                                            elem.category.map((elem_1, index_1)=>{
                                                return(
                                                    <div className="" key={ index_1 }>
                                                        { elem_1.name }
                                                    </div>
                                                )                                    
                                            })
                                        }
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}