import React, { useState, useEffect } from "react";

import './RecoverPassword.css';

import { RegisterModalObserver, SetModalState } from "interface/PopUp";

import { SvgClose } from "components/SvgFile";

import { RecoverEmail } from "services/Login";

export default function PopUp_RecoverPassword(props){
    
    const [ showPopUp, setShowPopUp ]       = useState(false);
    const [ email, setEmail ]               = useState('');
    const [ msgReturn, setMsgReturn ]       = useState('');
    const [ checkedEmail, setCheckedEmail ] = useState(false);

    useEffect(()=>{
        RegisterModalObserver('RecoverPassword', setShowPopUp);
    }, []);

    function Register(event){
        event.preventDefault();
        props.setLoading(true);
        RecoverEmail(email, ()=>{ CallbackSuccess() }, ()=>{ CallbackError_Send() }, ()=>{ CallbackError_NotEmail() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        props.setLoading(false);
        setCheckedEmail(false);
        setMsgReturn('Foi enviado um link para seu e-mail com instruções para criar uma nova senha.');
    }

    function CallbackError_Send(){
        props.setLoading(false);
        setCheckedEmail(true);
        setMsgReturn('E-mail não enviado, tente novamente!');
    }

    function CallbackError_NotEmail(){
        props.setLoading(false);
        setCheckedEmail(true);
        setMsgReturn('E-mail não encontrado!');
    }

    function CallbackError(){
        props.setLoading(false);
        setCheckedEmail(true);
        setMsgReturn('Erro ao enviar e-mail, tente novamente!');
    }

    function ResetSend(){   
        setEmail('');
        setMsgReturn('');  
    }

    function ClosePopUp(){     
        setEmail('');
        setMsgReturn('');   
        SetModalState('RecoverPassword', false);
    }

    return (
        (
            showPopUp ? 
            <form onSubmit={ Register }>
                <div className="PopUp">
                    <div className="all recoverPassword">
                        <div className="div_data">                         
                            <div className="content">
                                <div className="div_title">
                                    <div className="title_popup">Recuperação de senha</div>
                                    <div className="">
                                        <a onClick={ ()=>{ ClosePopUp() } }>
                                            <SvgClose color="#F00000" className="icons" />
                                        </a>
                                    </div>
                                </div>
                                {
                                    msgReturn == "" ? 
                                    <>
                                        <div className="div_input">
                                            <div className="div_regi_data">
                                                <input type="email" className="input_email" required onChange={ (e)=>{ setEmail(e.target.value) } } />
                                            </div> 
                                            <span className="sub_name_input">Digite seu e-mail aqui</span>
                                        </div>
                                        <div className="button_popup">
                                            <button className="button_save_popup btn_save">
                                                Enviar
                                            </button>
                                        </div>
                                    </>
                                    :
                                    <div className="div_button">
                                        <div className="show_msg">
                                            { msgReturn }
                                        </div>
                                        <div className="button_popup return_resposta">
                                            {
                                                checkedEmail == false ? null :
                                                <div className="button_save_popup" onClick={ ()=>{ ResetSend() } }>
                                                    Tentar novamente
                                                </div>
                                            }
                                            <div className="button_save_popup" onClick={ ()=>{ ClosePopUp() } }>
                                                Fechar
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div> 
                    </div>
                </div>
            </form>
            : <React.Fragment></React.Fragment>
        )
    )
}