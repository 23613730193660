import { useState, useEffect } from "react";

import './PersonalizedService.css';

import Signature from "components/Signature";

export default function Model_PersonalizedService(props){

    const [ dataPage, setDataPage ] = useState(props.data);

    useEffect(()=>{        
        // if(document.body.clientWidth <= 910){
        //     const checkedDiv = setInterval(() => {
        //         if(document.getElementById('PersonalizedService_Bg')){
        //             document.getElementById('PersonalizedService_Bg').style.display = 'none';
        //             clearInterval(checkedDiv);
        //         }                
        //     }, 100);
        // }

        // window.addEventListener('resize', function () {
        //     if(window.innerWidth <= 910){
        //         this.document.getElementById('PersonalizedService_Bg').style.display = 'none'; 
        //     }else {
        //         this.document.getElementById('PersonalizedService_Bg').style.display = null;
        //     }
        // });
    }, []);

    return(
        <div className="Model_PersonalizedService" style={ { background: dataPage.bg_color } }>
            <div className="container">
                <div className="div_text">
                    {
                        dataPage.title_1 ? <div className="title">{ dataPage.title_1 }</div> : null
                    }
                    {
                        dataPage.title_2 || dataPage.text_1 ?          
                        <div className="">     
                            {
                                dataPage.title_2 ? <div className="subtitle">{ dataPage.title_2 }</div> : null
                            } 
                            {
                                dataPage.text_1 ?                        
                                <div className="text" dangerouslySetInnerHTML={ { __html: dataPage.text_1.replaceAll('&#34;', '"') } } /> : null
                            }           
                        </div> : null
                    }     
                    {
                        dataPage.title_3 || dataPage.text_2 ?          
                        <div className="">    
                            {
                                dataPage.title_3 ? <div className="subtitle">{ dataPage.title_3 }</div> : null
                            } 
                            {
                                dataPage.text_2 ?                        
                                <div className="text" dangerouslySetInnerHTML={ { __html: dataPage.text_2.replaceAll('&#34;', '"') } } /> : null
                            } 
                        </div> : null
                    }    
                    {
                        dataPage.title_4 || dataPage.text_3 ?          
                        <div className="">    
                            {
                                dataPage.title_4 ? <div className="subtitle">{ dataPage.title_4 }</div> : null
                            } 
                            {
                                dataPage.text_3 ?                        
                                <div className="text" dangerouslySetInnerHTML={ { __html: dataPage.text_3.replaceAll('&#34;', '"') } } /> : null
                            } 
                        </div> : null
                    }   
                </div>
            </div>
            <div className="background_image" id="PersonalizedService_Bg">
                <img alt="img" src={ dataPage.bg_img } className="img_home" />
                <Signature origin={ "origin" } name={ "Name" } position="left" color="#ffffff" />
            </div>
        </div>
    )
}