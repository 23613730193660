import { useState, useEffect } from "react";

import './TeacherRecordings.css';

import { SetModalData, SetModalState } from "interface/PopUp";
import { GetUserData } from "interface/Users";
import PopUp_ShowFile from "components/PopUp/ShowFile";

export default function Model_TeacherRecordings(props){

    function OpenFile(type, file){        
        SetModalData('ShowFile', { "type": type, "file": file });
        SetModalState('ShowFile', true);
    }

    return(
        <div className="Model_TeacherRecordings">
            <div className="container">
                <div className="list_videos">
                    {
                        GetUserData('user').recording.map((elem, index)=>{
                            let video     = '';
                            let thumbnail = '';
                            if(elem.type == 'Youtube'){
                                let linkYoutube = elem.video.split('=');
                                video     = linkYoutube[1];
                                thumbnail = "https://img.youtube.com/vi/" + linkYoutube[1]  + "/maxresdefault.jpg";
                            }else {
                                let linkVimeo = elem.video.split('/');

                                video     = linkVimeo[3];
                                thumbnail = "https://vumbnail.com/" + linkVimeo[3]  + ".jpg";
                            }

                            return(
                                <div className="list_div_video" key={ index }  onClick={ ()=>{ OpenFile(elem.type, video) } }>
                                    <div className="show_video">
                                        <img alt={ "img_" + index } src={ thumbnail } className="img_video" />
                                    </div>
                                    <div className="title_video">
                                        { elem.title }
                                    </div>
                                    <div className="title_video title_data">
                                        Data do registro: { elem.date }
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            <PopUp_ShowFile />
        </div>
    )
}