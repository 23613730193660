import { useState, useEffect } from "react";

import './Course.css';

import Cookies from 'universal-cookie';

import { SetListPag } from "interface/Page";
import { GetDataPage } from "interface/Data";
import { GetUserData } from "interface/Users";
import { SetModalData, SetModalState } from "interface/PopUp";

import { SvgArrowLeft, SvgListSquare, SvgListline, SvgSearch } from "components/SvgFile";

import { cookie_email, cookie_passw } from "dataFixed";

import { Access } from "services/Login";

import { useNavigate } from "react-router-dom";

import PopUP_CheckedCourse from "components/PopUp/CheckedCourse";

export default function Model_Course(props){

    const cookies = new Cookies();

    const [ nameUser, setNameUser ]         = useState(GetUserData('user'));
    const [ dataPage, setDataPage ]         = useState(GetDataPage('course'));
    const [ listCategory, setListCategory ] = useState(GetDataPage('list_category'));

    const [ status, setStatus ]     = useState(false);
    const [ typeList, setTypeList ] = useState(false);
    const [ category, setCategory ] = useState('Mostrar todas as categorias');
    
    const [ search, setSearch ] = useState('');
    const [ width, setWidth ]   = useState(0);

    const navigate = useNavigate();

    function SearchInput(type, value){
        if(type == "input"){
            if(value){
                const newList = [];
                GetDataPage('course').forEach(item =>{
                    if(item.title.toLowerCase().indexOf(value.toLowerCase()) != -1){
                        newList.push(item);
                    }
                    if(item.text.toLowerCase().indexOf(value.toLowerCase()) != -1){
                        newList.push(item);
                    }
                });
                const duplicate = newList.filter((item, index) => newList.indexOf(item) === index);
                setDataPage(duplicate);
                setSearch(value);
            }else {
                setDataPage(GetDataPage('course'));
                setSearch('');
            }
        }else if(type == "select"){
            if(value == "all"){
                setDataPage(GetDataPage('course'));
                setCategory('Mostrar todas as categorias');

            }else {                
                const newList = [];
                GetDataPage('course').forEach(item =>{
                    item.category.forEach(item_1 =>{                    
                        if(item_1.name == value){
                            newList.push(item);
                        } 
                    }); 
                });
                const duplicate = newList.filter((item, index) => newList.indexOf(item) === index);
                setDataPage(duplicate);
                setCategory(value);
            }
        }
    }

    function OpenCategory(value){
        let accordion = document.getElementById('list_category');
        if(value == true){
            accordion.style.maxHeight = accordion.scrollHeight + "px";
            
        }else {
            accordion.style.maxHeight = null; 
        }
        setStatus(value);
    }

    function OpenCourse(id, type, video, title, title_url){
        if(cookies.get(cookie_passw) || cookies.get(cookie_email)){
            let return_pass  = cookies.get(cookie_passw);
            let return_email = cookies.get(cookie_email);
            Access('return', return_email, return_pass, ()=>{ CallbackSuccess(id) }, ()=>{ CallbackError(type, video, title) })
        }else {
            SetModalData("CheckedCourse", { "type": type, "video": video, "title": title, "title_url": title_url });
            SetModalState('CheckedCourse', true);
        }
    }

    function CallbackSuccess(id){
        props.ReturnIndex();
        navigate("/login");
        SetListPag('page', 'login');
        SetListPag('pageDash', 'dash_classe_detalis');
        SetListPag('pageDash_id_course', id); 
    }

    function CallbackError(id){

    }

    useEffect(()=>{        
        window.addEventListener('resize', function () {
            setWidth(window.innerWidth)
        });
    }, []);

    useEffect(()=>{
        if(width <= 680){
            setTypeList(false);
        }
    }, [width]);

    return(
        <div className="Model_Course">
            <div className="container">
                <div className="div_search">
                    <div className="type_list" onClick={ ()=>{ setTypeList(!typeList) } }>
                        {
                            typeList ?
                            <SvgListline color="#193C4E" className="show_type_icon" /> :
                            <SvgListSquare color="#193C4E" className="show_type_icon" />
                        }
                    </div>
                    <div className="search">
                        <SvgSearch color="#ffffff" className="icons_search" />
                        <input type="text" className="input_search" onChange={ (e)=>{ SearchInput('input', e.target.value) } } value={ search } placeholder="pesquisar..." />
                    </div>
                    <div className="div_select">                        
                        <div className="select_category" onClick={ ()=>{ OpenCategory(!status) } }>
                            <div className="name_category">{ category }</div>
                            <div className="show_icon">
                                {
                                    status ?
                                    <SvgArrowLeft className="icons_arrow open_icon" color="#ffffff" /> : 
                                    <SvgArrowLeft className="icons_arrow" color="#ffffff" />
                                }
                            </div>
                        </div>
                        <div className="list_category" id="list_category">
                            {
                                category == "Mostrar todas as categorias" ? null : 
                                <div className="show_name_category" onClick={ ()=>{ OpenCategory(!status); SearchInput('select', "all") } }>
                                    Mostrar todas as categorias
                                </div>
                            }

                            {
                                listCategory.map((elem, index)=>{
                                    return(
                                        <div className="show_name_category" key={ index } onClick={ ()=>{ OpenCategory(!status); SearchInput('select', elem) } }>
                                            { elem }
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>

                <div className={ typeList ? "div_list_courses list_line" : "div_list_courses list_square" }>
                    {
                        dataPage.length > 0 ?
                        dataPage.map((elem, index)=>{
                            return(
                                <div className="div_data_course" key={ index } onClick={ ()=>{ OpenCourse(elem.id, elem.type_video, elem.video, elem.title, elem.title_url) } }>
                                    <div className="courses_img" style={ { backgroundImage: 'url("' + elem.file + '")' } }>
                                        <img alt="img" src={ elem.file } className="img_" loading="lazy"/>
                                    </div>
                                    <div className="div_text">
                                        <div className="">{ elem.title }</div>
                                        <div className="text" id={ "div_text_" + index } dangerouslySetInnerHTML={ { __html: elem.text.replaceAll('&#34;', '"') } } />
                                    </div>
                                </div>
                            )
                        }) :
                        <div className="">
                            Nenhum curso encontrato com "{ search }"...
                        </div>
                    }
                </div>
            </div>
            <PopUP_CheckedCourse />
        </div>
    )
}