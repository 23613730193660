
import Cookies from 'universal-cookie';
import { cookiesRegister, cookie_site, cookie_dash, cookie_dash_id, cookie_type_payment, cookie_type_stage_payment, cookie_dash_id_classe, cookie_dash_id_module } from 'dataFixed';

const cookies = new Cookies(); 

let ListPag = {
    "page"               : "index",
    "pageDash"           : "course",
    "pageDash_id_course" : 0,
    "pageDash_id_module" : 0,
    "pageDash_id_classe" : 0,
    
    "typePayment"        : "",
    "stagePayment"       : 0,
    "remuve"             : ""
};

let NotifyListPag = {
    "page"               : [],
    "pageDash"           : [],
    "pageDash_id_course" : [],
    "pageDash_id_module" : [],
    "pageDash_id_classe" : [],
    
    "typePayment"        : [],
    "stagePayment"       : [],
    "remuve"             : []
}

export function SetListPag(key, value){    
    ListPag[key] = value;
    NotifyListPag[key].forEach(element => {
        element(value);
    });

    // Obtém a data atual
    const day = new Date();

    // Adiciona 3 dias
    const add3Days = new Date();
    add3Days.setDate(day.getDate() + 3);
    if(key == "page"){
        cookies.set(cookie_site, value, { path: '/', expires: add3Days }, cookiesRegister); 
    }

    // login    
    if(key == "pageDash"){
        cookies.set(cookie_dash, value, { path: '/', expires: add3Days }, cookiesRegister); 
    }
    if(key == "pageDash_id_course"){
        if(value == 'remuve'){
            cookies.remove(cookie_dash_id, '', { path: '/', expires: add3Days }, cookiesRegister); 
        }else {
            cookies.set(cookie_dash_id, value, { path: '/', expires: add3Days }, cookiesRegister); 
        }
    }
    if(key == "pageDash_id_module"){
        if(value == 'remuve'){
            cookies.remove(cookie_dash_id_module, '', { path: '/', expires: add3Days }, cookiesRegister); 
        }else {
            cookies.set(cookie_dash_id_module, value, { path: '/', expires: add3Days }, cookiesRegister); 
        }
    }
    if(key == "pageDash_id_classe"){
        if(value == 'remuve'){
            cookies.remove(cookie_dash_id_classe, '', { path: '/', expires: add3Days }, cookiesRegister); 
        }else {
            cookies.set(cookie_dash_id_classe, value, { path: '/', expires: add3Days }, cookiesRegister); 
        }
    }
}

export function GetListPag(key){
    return ListPag[key];
}

export function RegisterListPag(key, value){
    if(!NotifyListPag[key]){
        NotifyListPag[key] = []; 
    }
    NotifyListPag[key].push(value);
}

export function UnRegisterListPag(key, callback){
    if(NotifyListPag[key].length > 0){
        NotifyListPag[key] = NotifyListPag[key].filter((item) => {
            return item !== callback;
        });
    }
}