import { useState, useEffect, useRef } from "react";

import './Menu.css';

import { Link } from "react-router-dom";

import { GetUserData } from "interface/Users";
import { GetDataPage, RegisterDataPage, SetListDataSingle, UnRegisterDataPage } from "interface/Data";
import { GetListPag, RegisterListPag, SetListPag, UnRegisterListPag } from "interface/Page";

import { color_access } from "dataFixed";

import { SvgUser } from "components/SvgFile";

export default function Menu(props){

    const [ status, setStatus ]     = useState(false);
    const [ namePage, setNamePage ] = useState(GetListPag('page'));
    const [ listMenu, setListMenu ] = useState(GetDataPage('menuName'));

    function OpenMenu(value, page){
        setStatus(value);
        if(page){
            SetListPag('page', page);

            if(page != "login"){
                const newData = [];
                GetDataPage('course').map((elem, index)=>{
                    elem.list_module.map((elem_1, index_1)=>{
                        elem_1.status = false;
                        elem_1.list_classe.map((elem_2, index_2)=>{
                            elem_2.status = false;
                        });
                    })
                    newData.push(elem);
                })
                SetListDataSingle('course', newData);

                SetListPag('pageDash', 'course');
                SetListPag('pageDash_id_course', 'remuve');  
                SetListPag('pageDash_id_module', 'remuve');  
                SetListPag('pageDash_id_classe', 'remuve'); 
            }
        }

        if(value == false){
            props.ReturnIndex();

            document.getElementById('menu_opt').classList.add('close_menu');
            setTimeout(() => {
                document.getElementById('menu_opt').classList.remove('close_menu');
                document.getElementById('menu_opt').classList.remove('open_menu');
            }, 500);

        }else {
            document.getElementById('menu_opt').classList.add('open_menu');
        }
    }

    function ShowNameMenu(value){          
        if(Object.keys(GetUserData('user')).length > 0){
            let nameLogin = GetUserData('user').name.split(' ');
            return(
                <div className="data_user">
                    {
                        GetUserData('user').file !='' ?
                        <div className="img_user" style={ { backgroundImage: 'url("' + GetUserData('user').file + '")' } }>
                            {/* <img alt="login" src={ GetUserData('user').file } className="icon_menu_profile" /> */}
                        </div> :
                        <div className="">
                            <SvgUser color="#FFFFFF" className="icon_menu_profile" />
                        </div>
                    }
                    <div className="show_data_login">
                        <div className="">{ nameLogin[0] }</div>
                        <div className="type_access">
                            <span style={ { color: color_access[GetUserData('access_type')].color } }>
                                { GetUserData('user').nivel_page }
                            </span>
                        </div>
                    </div>
                </div>
            )
        }else {
            return value
        }
    }

    useEffect(()=>{
        RegisterListPag('page', setNamePage);
        RegisterDataPage('menuName', setListMenu);

        return ()=>{
            UnRegisterListPag('page', setNamePage);
            UnRegisterDataPage('menuName', setListMenu);
        };
    }, []);
    
    return(
        <header className="Menu">
            <div className="container">                    
                <Link to="/" className="div_logo" onClick={ ()=>{ SetListPag('page', 'index'); props.ReturnIndex(); } }>
                    <img src="./assets/logo.png" className="logotipo" />
                </Link>
                <label className="buttons__burger">
                    <input type="checkbox" id="burger" onChange={ (e)=>{ OpenMenu(!status, '') } } checked={ status } />
                    <span />
                    <span />
                    <span />
                </label>
                <div className="List_menu" id="menu_opt">
                    {
                        Object.keys(listMenu).map((elem, index)=>{
                            if(listMenu[index].page == 'blog'){
                                return(
                                    <a className="show_opt_menu" href="https://blog.crazypixel.com.br/" target="_blank" key={ index }>
                                        <div className="name_menu blog">{ listMenu[index].name }</div>
                                    </a>
                                )
                            }else {
                                if(Object.keys(GetUserData('user')).length > 0){
                                    if(listMenu[index].page == 'course'){                                        
                                        return(
                                            <Link className={ namePage == listMenu[index].page ? "show_opt_menu menu_active" : "show_opt_menu" } to="/login" onClick={ ()=>{ OpenMenu(false, listMenu[index].page); SetListPag('page', 'login'); props.ReturnIndex(); SetListPag('pageDash', 'course'); } } key={ index }>
                                                <div className="name_menu">
                                                    { listMenu[index].name }
                                                </div>
                                            </Link>
                                        )
                                    }else {
                                        return(
                                            <Link className={ namePage == listMenu[index].page ? "show_opt_menu menu_active" : "show_opt_menu" } to={ "/" + (listMenu[index].page == 'index' ? "" : listMenu[index].page) } onClick={ ()=>{ OpenMenu(false, listMenu[index].page); } } key={ index }>
                                                <div className="name_menu">
                                                    {
                                                        listMenu[index].page == 'login' ? ShowNameMenu(listMenu[index].name) : listMenu[index].name
                                                    }
                                                </div>
                                            </Link>
                                        )
                                    }

                                }else {                                    
                                    return(
                                        <Link className={ namePage == listMenu[index].page ? "show_opt_menu menu_active" : "show_opt_menu" } to={ "/" + (listMenu[index].page == 'index' ? "" : listMenu[index].page) } onClick={ ()=>{ OpenMenu(false, listMenu[index].page); } } key={ index }>
                                            <div className="name_menu">
                                                {
                                                    listMenu[index].page == 'login' ? ShowNameMenu(listMenu[index].name) : listMenu[index].name
                                                }
                                            </div>
                                        </Link>
                                    )
                                }
                            }
                        })
                    }
                </div>
            </div>
        </header>
    )
}