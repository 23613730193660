import React, { useState, useEffect } from "react";

import './CheckedCourse.css';

import { SvgClose } from "components/SvgFile";

import { SetListPag } from "interface/Page";
import { GetDataPage } from "interface/Data";
import { SetModalState, RegisterModalData, RegisterModalObserver } from "interface/PopUp";

export default function PopUP_CheckedCourse(){
    
    const [ showData, setShowData ] = useState(GetDataPage('settings'));

    const [ modalData, setModaldata ]     = useState({});
    const [ showPopUp, setShowPopUp ]     = useState(false);

    const [ type, setType ]         = useState('');
    const [ video, setVideo ]       = useState('');
    const [ title, setTitle ]       = useState('');
    const [ titleUrl, setTitleUrl ] = useState('');

    useEffect(()=>{
        RegisterModalData('CheckedCourse', setModaldata);
        RegisterModalObserver('CheckedCourse', setShowPopUp);
    }, []);

    useEffect(()=>{
        if(showPopUp == true){
            setType(modalData.type);
            setVideo(modalData.video);
            setTitle(modalData.title);
            setTitleUrl(modalData.title_url);
        }
    }, [showPopUp]);

    function ClosePopUp(){
        SetModalState('CheckedCourse', false);
    }

    return (
        (showPopUp ?
            <>
                <div className="PopUp">
                    <div className="all CheckedCourse">
                        <div className="div_data">
                            
                            <div className="close" onClick={ ()=>{ ClosePopUp() } }>
                                <SvgClose color="#ffffff" className="icons" />
                            </div> 
                           
                            <div className="content">
                                <div className="pop_title">{ title }</div>
                                <div className="pop_video">
                                    {
                                        type == "Vimeo" ?
                                        <iframe className="div_video" src={ "https://player.vimeo.com/video/" + video } frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen /> :
                                        <iframe className="div_video" src={ "https://www.youtube.com/embed/" + video } frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen />
                                    }
                                </div>
                                <div className="pop_title">Assista o curso completo com os Planos de acesso anual</div>

                                <div className="pop_text" dangerouslySetInnerHTML={ { __html: showData.text_course ? showData.text_course.replaceAll('&#34;', '"') : "" } } />

                                <div className="div_btn">
                                    <a className="pop_div_button" href="/mentoring">
                                        <div onClick={ ()=>{ SetListPag('page', "mentoring"); } }>
                                            <div className="pop_button">
                                                Saiba Mais sobre os planos
                                            </div>
                                        </div>
                                    </a>
                                    <a className="pop_div_button details_course" href={ "/" + titleUrl }>
                                        <div onClick={ ()=>{ SetListPag('page', "details"); } }>
                                            <div className="pop_button">
                                                Detalhes sobre o curso
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="close_all" onClick={ ()=>{ ClosePopUp(); } } />
                </div>
            </>
        : <React.Fragment></React.Fragment>)
    );
}