import { useState, useEffect } from "react";

import './VideoPresentation.css';

export default function Model_VideoPresentation(props){

    const [ dataPage, setDataPage ] = useState(props.data);

    return(
        <div className="Model_VideoPresentation" style={ { background: dataPage.bg_color } }>
            <div className="container">
                {
                    dataPage.video_code == "" ? null :
                    <div className="div_video">
                        {
                            dataPage.video_type == "Vimeo" ?
                            <iframe className="div_video" src={ "https://player.vimeo.com/video/" + dataPage.video_code + "?color=ffffff&amp;title=0&amp;byline=0&amp;portrait=0" } frameBorder="0" webkitallowfullscreen="" mozallowfullscreen="" allowFullScreen="" />
                            :
                            <iframe className="div_video" src={ "https://www.youtube.com/embed/" + dataPage.video_code + "?color=ffffff&amp;title=0&amp;byline=0&amp;portrait=0" } frameBorder="0" webkitallowfullscreen="" mozallowfullscreen="" allowFullScreen="" />
                        } 
                    </div>
                }
                <div className={ dataPage.video_code == "" ? "div_text div_full" : "div_text" }>
                    {
                        dataPage.title_1 == "" || dataPage.title_2 == "" ? null :
                        <div className="div_title">
                            {
                                dataPage.title_1 ? <div className={ dataPage.video == '' ? "subtitle subtitle_center" : "subtitle" }>{ dataPage.title_1 }</div> : null
                            }
                            {
                                dataPage.title_2 ? <div className="title">{ dataPage.title_2 }</div> : null
                            }
                        </div>
                    }
                    {
                        dataPage.text_1 ? <div className="text" dangerouslySetInnerHTML={ { __html: dataPage.text_1.replaceAll('&#34;', '"') } } /> : null
                    }                    
                    {
                        dataPage.title_3 ? <div className="subtitle">{ dataPage.title_3 }</div> : null
                    }
                    {
                        dataPage.text_2 ? <div className="text" dangerouslySetInnerHTML={ { __html: dataPage.text_2.replaceAll('&#34;', '"') } } /> : null
                    }        
                    {/* { ShowButton(dataPage.type_destiny, dataPage.destiny, dataPage.button) } */}
                </div>
            </div>
        </div>
    )
}