import { useState, useEffect } from "react";

import './Course.css';

import { GetListPag, RegisterListPag, SetListPag } from "interface/Page";
import { GetDataPage } from "interface/Data";
import { GetUserData } from "interface/Users";

import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';

import { Link } from "react-router-dom";

import Model_Course from "components/Model/Course";
import { SvgArrowLeft, SvgDiscord, SvgExercise, SvgVideo } from "components/SvgFile";

export default function Page_Course(props){

    const [ namePage, setNamePage ] = useState(GetListPag('page'));
    const [ dataPage, setDataPage ] = useState(GetDataPage('course'));

    const [ listCourse, setListCourse ] = useState(InitialData());
    const [ showCourse, setShowCourse ] = useState(ShowDataInitial());
    
    let countSlide                  = 0;
    const [ qtdCount, setQtdCount ] = useState(3);

    const SlideOpt = {
        infinite: true,
        indicators: true,
        arrows: true,
        duration: 6000
    };    

    function InitialData(){
        if(dataPage.filter(item => item.highlight == true)){
            const newData = dataPage.filter(item => item.highlight == true);
            return newData;
        }
        return '';
    }

    function ShowDataInitial(){
        let newData = [];
        if(listCourse.length > 0){
            if(listCourse.length == 1){
                newData.push(listCourse[0]);
                newData.push(listCourse[0]);
                newData.push(listCourse[0]);

            }else if(listCourse.length == 2){
                newData.push(listCourse[0]);
                newData.push(listCourse[1]);
                newData.push(listCourse[0]);
                newData.push(listCourse[1]);

            }else if(listCourse.length == 3){
                newData.push(listCourse[0]);
                newData.push(listCourse[1]);
                newData.push(listCourse[2]);
            }else {
                newData.push(listCourse);
            }
        }
        return newData;
    } 
    
    function AltPosition_prev(){
        if(document.querySelector('.div_list_img')){
            countSlide = 0;

            var stream = document.querySelector('.div_list_img');
            var items = document.querySelectorAll('.show_img');
            
            stream.insertBefore(items[items.length - 1], items[0]);
            items = document.querySelectorAll('.show_img');
        }
    }
    
    function AltPosition_next(){
        if(document.querySelector('.div_list_img')){
            countSlide = 0;

            var stream = document.querySelector('.div_list_img');
            var items = document.querySelectorAll('.show_img');
            
            stream.appendChild(items[0]);
            items = document.querySelectorAll('.show_img');
        }
    }

    function StartSlideShow(){
        if(document.querySelector('.div_list_img')){
            var stream = document.querySelector('.div_list_img');
            var items = document.querySelectorAll('.show_img');
            
            stream.appendChild(items[0]);
            items = document.querySelectorAll('.show_img'); 
        }       
    }

    function Sequence(){
        countSlide++;
        if(countSlide == qtdCount){
            countSlide = 0;
            StartSlideShow(); 
        }
    }
    
    useEffect(()=>{
        document.title = 'Cursos';
        RegisterListPag('page', setNamePage);

        const altSlideShow = setInterval(()=>{ Sequence() }, 1000);
            
        return ()=>{
            clearInterval(altSlideShow)
        }  
    }, []);

    return(
        <div className="Page_Course">

            <div className="slideshow">
                <Slide { ...SlideOpt }>
                    {
                        showCourse.map((elem, index)=>{              
                            return(
                                <div className="each-slide-effect" key={ index }>
                                    <div className="container">
                                        <div className="div_inf_course">
                                            <div className="highlight_title">
                                                { elem.title }
                                            </div>
                                            <div className="highlight_text" dangerouslySetInnerHTML={ { __html: elem.text.replaceAll('&#34;', '"') } } />
                                            <div className="button">                                                
                                                <a className="" href={ "/" + elem.title_url }>
                                                    <div className="button_text" onClick={ ()=>{ SetListPag('page', "details"); } }>
                                                        Detalhes sobre o curso
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="div_img">
                                            <img alt="img" src={ elem.img } className="img_course" />
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </Slide>
            </div>

            {/* <div className="slideshow">   
                {
                    listCourse.length > 1 ? 
                    <>
                        <div className="div_arrow" onClick={ ()=>{ AltPosition_prev() } }>
                            <SvgArrowLeft className="icons icons_left" color="#37a7e5" />
                        </div>
                        <div className="div_arrow" onClick={ ()=>{ AltPosition_next() } }>
                            <SvgArrowLeft className="icons" color="#37a7e5" />
                        </div>
                    </> : null
                }
                <div className="div_list_img">
                    {
                        showCourse.map((elem, index)=>{
                            return(
                                <div className="show_img" key={ index }>
                                    <div className="container">
                                        <div className="div_inf_course">
                                            <div className="highlight_title">
                                                { elem.title }
                                            </div>
                                            <div className="highlight_text" dangerouslySetInnerHTML={ { __html: elem.text.replaceAll('&#34;', '"') } } />
                                            <div className="button">                                                
                                                <a className="" href={ "/" + elem.title_url }>
                                                    <div className="button_text" onClick={ ()=>{ SetListPag('page', "details"); } }>
                                                        Detalhes sobre o curso
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="div_img">
                                            <img alt="img" src={ elem.file } className="img_course" />
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div> */}
            <Model_Course ReturnIndex={ props.ReturnIndex } CkickPage={ props.CkickPage } setLoading={ props.setLoading } />
        </div>
    )
}